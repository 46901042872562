/* html, body, #root, #root>div { */
html,
body,
#root,
#root {
  height: 100%;
}

html,
body {
  font-weight: 400;
  font-smooth: antialias;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}
